@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@300;400&family=Poppins:wght@300;400;500;600,700,800&display=swap);
:root {
    --primary-color: 255, 255, 255;
    --secondary-color: 230, 230, 230;
    --background-color: 230, 230, 230;
    --primary-text-color: 35, 35, 35;
    --secondary-text-color: 220, 220, 220;
    --complementary: 255, 118, 0;
    --opacity: 1;
    --dark: dark
}

body {
    background-color: rgb(230, 230, 230);
    background-color: rgb(var(--background-color));
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.MuiTabs-root .MuiButtonBase-root {
    opacity: 1 !important;
    background-color: rgb(255, 255, 255) !important;
    background-color: rgb(var(--primary-color)) !important;
}

.MuiTabs-root .MuiButtonBase-root svg {
    fill: rgb(35, 35, 35) !important;
    fill: rgb(var(--primary-text-color)) !important;
}

.swal-title,
.swal-text {
    color: rgb(35, 35, 35);
    color: rgb(var(--primary-text-color))
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}


p,
h3,
h5,
b {
    color: rgb(35, 35, 35);
    color: rgb(var(--primary-text-color));
}

.MuiListItemText-root,
.MuiListItemIcon-root {
    background-color: rgb(255, 255, 255, 0) !important;
    background-color: rgb(var(--primary-color), 0) !important;
}

.MuiListItemText-root span {
    color: rgb(35, 35, 35) !important;
    color: rgb(var(--primary-text-color)) !important;
}

h6 {
    color: rgb(35, 35, 35) !important;
    color: rgb(var(--primary-text-color)) !important;
}

.MuiTypography-root,
.MuiTypography-body1 {
    color: rgb(35, 35, 35);
    color: rgb(var(--primary-text-color));
}

.MuiDialog-root {
    background-color: transparent;
}

.MuiBackdrop-root,
.MuiDialog-container {
    background-color: rgba(35, 35, 35, 0.05) !important;
    background-color: rgba(var(--primary-text-color), 0.05) !important;
}

div {
    background-color: rgb(255, 255, 255);
    background-color: rgb(var(--primary-color));
    color: rgb(35, 35, 35) !important;
    color: rgb(var(--primary-text-color)) !important;
    border-color: rgb(35, 35, 35, 0.3) !important;
    border-color: rgb(var(--primary-text-color), 0.3) !important;
}

#root div header>div,
#root div header>div>div>button span svg {
    background-color: rgb(255, 118, 0);
    background-color: rgb(var(--complementary));
    color: rgb(255, 255, 255) !important;
    color: rgb(var(--primary-color)) !important;
    border-color: rgb(35, 35, 35, 0.3) !important;
    border-color: rgb(var(--primary-text-color), 0.3) !important;
    fill: rgb(255, 255, 255) !important;
    fill: rgb(var(--primary-color)) !important;
}

#root div header>div,
#root div header>div>div {
    background-color: rgb(255, 118, 0);
    background-color: rgb(var(--complementary));
}

#root div header {
    fill: rgb(255, 255, 255) !important;
    fill: rgb(var(--primary-color)) !important;
}

#root div header div>h6 * {
    color: rgb(255, 255, 255) !important;
    color: rgb(var(--primary-color)) !important;
}

#root div div div div>header {
    color: rgb(35, 35, 35) !important;
    color: rgb(var(--primary-text-color)) !important;
    background-color: rgb(255, 255, 255) !important;
    background-color: rgb(var(--primary-color)) !important;
}

#root div div div div div header div>div {
    color: rgb(35, 35, 35) !important;
    color: rgb(var(--primary-text-color)) !important;
    background-color: rgb(255, 255, 255) !important;
    background-color: rgb(var(--primary-color)) !important;
}

#agreements-table div>div {
    background-color: transparent !important;
}

.makeStyles-input-62 div div input {
    background-color: rgb(255, 255, 255) !important;
    background-color: rgb(var(--primary-color)) !important;
    color: rgb(35, 35, 35) !important;
    color: rgb(var(--primary-text-color)) !important;
    border-color: rgb(35, 35, 35, 0.3) !important;
    border-color: rgb(var(--primary-text-color), 0.3) !important;
}

.MuiBox-root {
    background-color: rgb(230, 230, 230);
    background-color: rgb(var(--secondary-color));
}

.makeStyles-avatar-66 {
    background-color: rgba(35, 35, 35, 0.2) !important;
    background-color: rgba(var(--primary-text-color), 0.2) !important;
}

table,
tr,
th,
td {
    background-color: transparent !important;
    color: rgb(35, 35, 35) !important;
    color: rgb(var(--primary-text-color)) !important;
    border-color: rgb(35, 35, 35, 0.3) !important;
    border-color: rgb(var(--primary-text-color), 0.3) !important;
}

.MuiContainer-root {
    background-color: rgb(230, 230, 230);
    background-color: rgb(var(--secondary-color));
}

.swal-overlay,
.swal-overlay--show-modal,
.MuiDrawer-root,
.MuiDrawer-modal {
    background-color: rgba(35, 35, 35, 0.2) !important;
    background-color: rgba(var(--primary-text-color), 0.2) !important;
}

.swal-icon--success__line,
.swal-icon span {
    background-color: green !important;
    color: green !important;
    fill: green !important;
}

.swal-icon--success__hide-corners {
    background-color: transparent;
    color: green;
}

.swal-icon::before,
.swal-icon::after {
    background-color: transparent;
    color: green;
}

.swal-icon--success__ring {
    background-color: transparent;
}

.swal-modal {
    background-color: rgb(255, 255, 255);
    background-color: rgb(var(--primary-color));
}

.swal-content p {
    color: rgb(35, 35, 35) !important;
    color: rgb(var(--primary-text-color)) !important;
}

.MuiPopover-root {
    background-color: rgb(35, 35, 35, 0);
    background-color: rgb(var(--primary-text-color), 0);
}

span {
    color: rgb(35, 35, 35);
    color: rgb(var(--primary-text-color));
}

.MuiTabs-scroller>span {
    background-color: rgb(255, 118, 0) !important;
    background-color: rgb(var(--complementary)) !important;

}

.MuiButton-label {
    color: rgb(255, 255, 255);
    color: rgb(var(--primary-color));
}

#custom-css-standard-input {
    background-color: transparent !important;
    border-color: aliceblue !important;
}

svg {
    fill: rgb(35, 35, 35) !important;
    fill: rgb(var(--primary-text-color)) !important;
}

#Layer_1 {
    fill: rgb(255, 255, 255) !important;
    fill: rgb(var(--primary-color)) !important;
}

#darkmodeIcon svg {
    fill: rgb(35, 35, 35) !important;
    fill: rgb(var(--primary-text-color)) !important;
}

button,
.swal-button,
.swal-button--confirm {
    color: rgb(255, 255, 255) !important;
    color: rgb(var(--primary-color)) !important;
}

.MuiTextField-root,
.MuiInputLabel-root,
.MuiFormLabel-root {
    color: rgb(35, 35, 35) !important;
    color: rgb(var(--primary-text-color)) !important;
}

.makeStyles-textColor-35 {
    color: rgb(35, 35, 35) !important;
    color: rgb(var(--primary-text-color)) !important;
}

.MuiGrid-root {
    background-color: transparent !important;
}

.MuiPaper-root {
    background-color: rgb(255, 255, 255) !important;
    background-color: rgb(var(--primary-color)) !important;
    border-color: rgb(255, 118, 0) !important;
    border-color: rgb(var(--complementary)) !important;
}

.MuiFormControl-root,
.MuiTextField-root {
    background-color: transparent !important;
}

#loginLinks {
    color: rgb(255, 118, 0) !important;
    color: rgb(var(--complementary)) !important;
}

textarea {
    color: rgb(35, 35, 35) !important;
    color: rgb(var(--primary-text-color)) !important;
    background-color: rgb(255, 255, 255) !important;
    background-color: rgb(var(--primary-color)) !important;
}

.MuiExpansionPanelSummary-content {
    display: block !important;
}

.MuiIconButton-label div {
    background-color: transparent;
}

.MuiTabs-scrollButtons {
    background-color: rgb(255, 255, 255) !important;
    background-color: rgb(var(--primary-color)) !important;
}

.rdt_TableHeader {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.rdt_Pagination {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

#agreements-table div,
#agreements-table header,
#agreements-table div nav {
    background-color: rgb(255, 255, 255);
    background-color: rgb(var(--primary-color));
}

#agreements-table div {
    color: rgb(35, 35, 35);
    color: rgb(var(--primary-text-color));
}

.buttons * {
    color: rgb(35, 35, 35) !important;
    color: rgb(var(--primary-text-color)) !important;
}

.welcome-card {
    background-color: 'rgb(var(--complementary))' !important;
}
.nunito {
  font-family: 'nunito', sans-serif !important;
}
.poppins {
  font-family: 'montserrat', sans-serif !important;
}

.welcome-image {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  height:100vw;
  width: auto;
}

.welcome {
  z-index: 2;
  position: absolute;
  right: -100%;
  top: 0;
  height:100vw;
  width: 100vw;
  transition-property: transform, opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 800ms;
}

.welcome-active {
  transform: translateX(-100vw);
}

.welcome-not-active {
  transform: translateX(100vw);
}

.welcome-background {
  background-color: rgba(238, 88, 36);
  position: absolute;
  z-index: 3;
  left: 0;
  top: 0;
  height:100vw;
  width: 100vw;
  transition-property: transform, opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 800ms;
}

.welcome-background-active {
  visibility: visible;
  opacity: 0.2;
}

.welcome-background-not-active {
  visibility: hidden;
  opacity: 0.3;
}

.welcome-card {
  position: absolute;
  width: 150vw;
  height: 100vw;
  rotate: -75deg;
  top: -20vw;
  left: -130vw;
  z-index: 3;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 800ms;
}

.welcome-card-active {
  transform: translateY(55vw);
}

.welcome-card-not-active {
  transform: translateY(-50vw);
}

.welcome-card-content {
  background-color: transparent;
  position: absolute;
  max-width: 40vw;
  top: 5vw;
  left: 50px;
  z-index: 6;
}

.welcome-card-logo {
  z-index: 2;
  position: relative;
  left: -100%;
  width: 30vw;
  transition-property: transform, opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 800ms;
}

.welcome-card-logo-active {
  transform: translateX(45vw);
}

.welcome-card-logo-not-active {
  transform: translateX(-50vw);
}

.welcome-card-text {
  background-color: transparent;
  font-weight: 500;
  font-size: 25px;
  letter-spacing: 0.01rem;
  color: rgba(var(--primary-text-color), 0.8);
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 800ms;
}

.welcome-card-text-active {
  opacity: 1;
}

.welcome-card-text-not-active {
  opacity: 0;
}

.font-size-title {
  font-size: clamp(30px, 2.5vw, 1rem);
  color: rgba(var(--complementary), 0.8);
  background-color: transparent;
  font-weight: 400;
  text-align: center;
}

.font-size-text {
  background-color: transparent;
  text-align: justify;
  font-size: clamp(15px, 1.5vw, 2rem);
}

.welcome-card-button {
  padding-top: 4vw;
  text-align: center;
  height: 100%;
  position: relative;
  background-color: transparent;
  bottom: -0px;
}

.welcome-mobile {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  text-align: center;
  z-index: 4;
  padding-left: 16px;
  padding-right: 36px;
  padding-top: 16px;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 800ms;
}

.welcome-mobile-active {
  transform: translateY(0);  
}

.welcome-mobile-not-active {
  transform: translateX(-100%);  
}

.welcome-mobile img {
  width: 80%;
}

.welcome-mobile .title {
  font-size: 30px;
  padding-right: 2rem;
  color: rgba(var(--complementary), 0.8);
  background-color: transparent;
  font-weight: 400;
  text-align: center;
}

.welcome-mobile .text {
  background-color: transparent;
  text-align: justify;
  font-size: 20px;
  padding-right: 2rem;
}

.help-icon {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 15px;
  cursor: pointer;
}

@media only screen and (max-width: 1024px) {
  .welcome {
    display: none;
  }
  .welcome-background {
    display: none;
  }
  .welcome-card {
    display: none;
  }
  .welcome-card-content {
    display: none;
  }
}

@media only screen and (min-width: 1024px) {
  .welcome-mobile {
    display: none;
  }
}
