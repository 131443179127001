@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400&family=Poppins:wght@300;400;500;600,700,800&display=swap');

.nunito {
  font-family: 'nunito', sans-serif !important;
}
.poppins {
  font-family: 'montserrat', sans-serif !important;
}

.welcome-image {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  height:100vw;
  width: auto;
}

.welcome {
  z-index: 2;
  position: absolute;
  right: -100%;
  top: 0;
  height:100vw;
  width: 100vw;
  transition-property: transform, opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 800ms;
}

.welcome-active {
  transform: translateX(-100vw);
}

.welcome-not-active {
  transform: translateX(100vw);
}

.welcome-background {
  background-color: rgba(238, 88, 36);
  position: absolute;
  z-index: 3;
  left: 0;
  top: 0;
  height:100vw;
  width: 100vw;
  transition-property: transform, opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 800ms;
}

.welcome-background-active {
  visibility: visible;
  opacity: 0.2;
}

.welcome-background-not-active {
  visibility: hidden;
  opacity: 0.3;
}

.welcome-card {
  position: absolute;
  width: 150vw;
  height: 100vw;
  rotate: -75deg;
  top: -20vw;
  left: -130vw;
  z-index: 3;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 800ms;
}

.welcome-card-active {
  transform: translateY(55vw);
}

.welcome-card-not-active {
  transform: translateY(-50vw);
}

.welcome-card-content {
  background-color: transparent;
  position: absolute;
  max-width: 40vw;
  top: 5vw;
  left: 50px;
  z-index: 6;
}

.welcome-card-logo {
  z-index: 2;
  position: relative;
  left: -100%;
  width: 30vw;
  transition-property: transform, opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 800ms;
}

.welcome-card-logo-active {
  transform: translateX(45vw);
}

.welcome-card-logo-not-active {
  transform: translateX(-50vw);
}

.welcome-card-text {
  background-color: transparent;
  font-weight: 500;
  font-size: 25px;
  letter-spacing: 0.01rem;
  color: rgba(var(--primary-text-color), 0.8);
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 800ms;
}

.welcome-card-text-active {
  opacity: 1;
}

.welcome-card-text-not-active {
  opacity: 0;
}

.font-size-title {
  font-size: clamp(30px, 2.5vw, 1rem);
  color: rgba(var(--complementary), 0.8);
  background-color: transparent;
  font-weight: 400;
  text-align: center;
}

.font-size-text {
  background-color: transparent;
  text-align: justify;
  font-size: clamp(15px, 1.5vw, 2rem);
}

.welcome-card-button {
  padding-top: 4vw;
  text-align: center;
  height: 100%;
  position: relative;
  background-color: transparent;
  bottom: -0px;
}

.welcome-mobile {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  text-align: center;
  z-index: 4;
  padding-left: 16px;
  padding-right: 36px;
  padding-top: 16px;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 800ms;
}

.welcome-mobile-active {
  transform: translateY(0);  
}

.welcome-mobile-not-active {
  transform: translateX(-100%);  
}

.welcome-mobile img {
  width: 80%;
}

.welcome-mobile .title {
  font-size: 30px;
  padding-right: 2rem;
  color: rgba(var(--complementary), 0.8);
  background-color: transparent;
  font-weight: 400;
  text-align: center;
}

.welcome-mobile .text {
  background-color: transparent;
  text-align: justify;
  font-size: 20px;
  padding-right: 2rem;
}

.help-icon {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 15px;
  cursor: pointer;
}

@media only screen and (max-width: 1024px) {
  .welcome {
    display: none;
  }
  .welcome-background {
    display: none;
  }
  .welcome-card {
    display: none;
  }
  .welcome-card-content {
    display: none;
  }
}

@media only screen and (min-width: 1024px) {
  .welcome-mobile {
    display: none;
  }
}