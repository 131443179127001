:root {
    --primary-color: 255, 255, 255;
    --secondary-color: 230, 230, 230;
    --background-color: 230, 230, 230;
    --primary-text-color: 35, 35, 35;
    --secondary-text-color: 220, 220, 220;
    --complementary: 255, 118, 0;
    --opacity: 1;
    --dark: dark
}

body {
    background-color: rgb(var(--background-color));
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.MuiTabs-root .MuiButtonBase-root {
    opacity: 1 !important;
    background-color: rgb(var(--primary-color)) !important;
}

.MuiTabs-root .MuiButtonBase-root svg {
    fill: rgb(var(--primary-text-color)) !important;
}

.swal-title,
.swal-text {
    color: rgb(var(--primary-text-color))
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}


p,
h3,
h5,
b {
    color: rgb(var(--primary-text-color));
}

.MuiListItemText-root,
.MuiListItemIcon-root {
    background-color: rgb(var(--primary-color), 0) !important;
}

.MuiListItemText-root span {
    color: rgb(var(--primary-text-color)) !important;
}

h6 {
    color: rgb(var(--primary-text-color)) !important;
}

.MuiTypography-root,
.MuiTypography-body1 {
    color: rgb(var(--primary-text-color));
}

.MuiDialog-root {
    background-color: transparent;
}

.MuiBackdrop-root,
.MuiDialog-container {
    background-color: rgba(var(--primary-text-color), 0.05) !important;
}

div {
    background-color: rgb(var(--primary-color));
    color: rgb(var(--primary-text-color)) !important;
    border-color: rgb(var(--primary-text-color), 0.3) !important;
}

#root div header>div,
#root div header>div>div>button span svg {
    background-color: rgb(var(--complementary));
    color: rgb(var(--primary-color)) !important;
    border-color: rgb(var(--primary-text-color), 0.3) !important;
    fill: rgb(var(--primary-color)) !important;
}

#root div header>div,
#root div header>div>div {
    background-color: rgb(var(--complementary));
}

#root div header {
    fill: rgb(var(--primary-color)) !important;
}

#root div header div>h6 * {
    color: rgb(var(--primary-color)) !important;
}

#root div div div div>header {
    color: rgb(var(--primary-text-color)) !important;
    background-color: rgb(var(--primary-color)) !important;
}

#root div div div div div header div>div {
    color: rgb(var(--primary-text-color)) !important;
    background-color: rgb(var(--primary-color)) !important;
}

#agreements-table div>div {
    background-color: transparent !important;
}

.makeStyles-input-62 div div input {
    background-color: rgb(var(--primary-color)) !important;
    color: rgb(var(--primary-text-color)) !important;
    border-color: rgb(var(--primary-text-color), 0.3) !important;
}

.MuiBox-root {
    background-color: rgb(var(--secondary-color));
}

.makeStyles-avatar-66 {
    background-color: rgba(var(--primary-text-color), 0.2) !important;
}

table,
tr,
th,
td {
    background-color: transparent !important;
    color: rgb(var(--primary-text-color)) !important;
    border-color: rgb(var(--primary-text-color), 0.3) !important;
}

.MuiContainer-root {
    background-color: rgb(var(--secondary-color));
}

.swal-overlay,
.swal-overlay--show-modal,
.MuiDrawer-root,
.MuiDrawer-modal {
    background-color: rgba(var(--primary-text-color), 0.2) !important;
}

.swal-icon--success__line,
.swal-icon span {
    background-color: green !important;
    color: green !important;
    fill: green !important;
}

.swal-icon--success__hide-corners {
    background-color: transparent;
    color: green;
}

.swal-icon::before,
.swal-icon::after {
    background-color: transparent;
    color: green;
}

.swal-icon--success__ring {
    background-color: transparent;
}

.swal-modal {
    background-color: rgb(var(--primary-color));
}

.swal-content p {
    color: rgb(var(--primary-text-color)) !important;
}

.MuiPopover-root {
    background-color: rgb(var(--primary-text-color), 0);
}

span {
    color: rgb(var(--primary-text-color));
}

.MuiTabs-scroller>span {
    background-color: rgb(var(--complementary)) !important;

}

.MuiButton-label {
    color: rgb(var(--primary-color));
}

#custom-css-standard-input {
    background-color: transparent !important;
    border-color: aliceblue !important;
}

svg {
    fill: rgb(var(--primary-text-color)) !important;
}

#Layer_1 {
    fill: rgb(var(--primary-color)) !important;
}

#darkmodeIcon svg {
    fill: rgb(var(--primary-text-color)) !important;
}

button,
.swal-button,
.swal-button--confirm {
    color: rgb(var(--primary-color)) !important;
}

.MuiTextField-root,
.MuiInputLabel-root,
.MuiFormLabel-root {
    color: rgb(var(--primary-text-color)) !important;
}

.makeStyles-textColor-35 {
    color: rgb(var(--primary-text-color)) !important;
}

.MuiGrid-root {
    background-color: transparent !important;
}

.MuiPaper-root {
    background-color: rgb(var(--primary-color)) !important;
    border-color: rgb(var(--complementary)) !important;
}

.MuiFormControl-root,
.MuiTextField-root {
    background-color: transparent !important;
}

#loginLinks {
    color: rgb(var(--complementary)) !important;
}

textarea {
    color: rgb(var(--primary-text-color)) !important;
    background-color: rgb(var(--primary-color)) !important;
}

.MuiExpansionPanelSummary-content {
    display: block !important;
}

.MuiIconButton-label div {
    background-color: transparent;
}

.MuiTabs-scrollButtons {
    background-color: rgb(var(--primary-color)) !important;
}

.rdt_TableHeader {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.rdt_Pagination {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

#agreements-table div,
#agreements-table header,
#agreements-table div nav {
    background-color: rgb(var(--primary-color));
}

#agreements-table div {
    color: rgb(var(--primary-text-color));
}

.buttons * {
    color: rgb(var(--primary-text-color)) !important;
}

.welcome-card {
    background-color: 'rgb(var(--complementary))' !important;
}